import gql from "graphql-tag";

export const Checkout = {
    Queries: {
        AmazonPayload: gql`
        query amazonPayload($shoppingBasketItems: [ShoppingBasketItem], $shippingTypeId: ID, $code: String, $referrerId: ID) {
            amazonPayload(shoppingBasketItems: $shoppingBasketItems, shippingTypeId: $shippingTypeId, code: $code, referrerId: $referrerId)
        }`,
        AmazonPayloadByOrderIdentifier: gql`
        query amazonPayloadByOrderIdentifier($orderIdentifier: String) {
            amazonPayloadByOrderIdentifier(orderIdentifier: $orderIdentifier)
        }`,
        AmazonButtonSignature: gql`
        query amazonButtonSignature($payload: JSON) {
            amazonButtonSignature(payload: $payload)
        }`,
        VoucherAuthentificationUrl: gql`
        query voucherAuthentificationUrl($voucherId: ID) {
            voucherAuthentificationUrl(voucherId: $voucherId)
        }`,
        Items: gql`
        query items($orderId: ID) {
            items(orderId: $orderId)
        }`,
    },
    Mutations: {
        CreatePaypalOrder: gql`
        mutation createPaypalOrder($shoppingBasketItems: [ShoppingBasketItem], $shippingTypeId: ID, $code: String, $referrerId: ID) {
            createPaypalOrder(shoppingBasketItems: $shoppingBasketItems, shippingTypeId: $shippingTypeId, code: $code, referrerId: $referrerId) {
                IsSuccess
                Message
                StringData
            }
        }`,
        CreateKlarnaOrder: gql`
        mutation createKlarnaOrder($shoppingBasketItems: [ShoppingBasketItem], $shippingTypeId: ID, $code: String, $referrerId: ID) {
            createKlarnaOrder(shoppingBasketItems: $shoppingBasketItems, shippingTypeId: $shippingTypeId, code: $code, referrerId: $referrerId) {
                IsSuccess
                Message
                StringData
            }
        }`,
        ConfirmPaypalOrder: gql`
        mutation confirmPaypalOrder($token: String, $payerId: String) {
            confirmPaypalOrder(token: $token, payerId: $payerId) {
                IsSuccess
                Message
                StringData
            }
        }`,
        ConfirmKlarnaOrder: gql`
        mutation confirmKlarnaOrder($klarnaOrderId: String) {
            confirmKlarnaOrder(klarnaOrderId: $klarnaOrderId) {
                IsSuccess
                Message
                StringData
            }
        }`,
        UpdateAmazonOrder: gql`
        mutation updateAmazonOrder($amazonCheckoutSessionId: String) {
            updateAmazonOrder(amazonCheckoutSessionId: $amazonCheckoutSessionId) {
                IsSuccess
                Message
                JsonData
            }
        }`,
        ConfirmAmazonOrder: gql`
        mutation confirmAmazonOrder($amazonCheckoutSessionId: String) {
            confirmAmazonOrder(amazonCheckoutSessionId: $amazonCheckoutSessionId) {
                IsSuccess
                Message
                StringData
            }
        }`,
        ConfirmTransferOrder: gql`
        mutation confirmTransferOrder($shoppingBasketItems: [ShoppingBasketItem], $shippingTypeId: ID, $customerInvoiceAddressId: ID, $customerAddressId: ID, $code: String, $referrerId: ID) {
            confirmTransferOrder(shoppingBasketItems: $shoppingBasketItems, shippingTypeId: $shippingTypeId, customerInvoiceAddressId: $customerInvoiceAddressId, customerAddressId: $customerAddressId, code: $code, referrerId: $referrerId) {
                IsSuccess
                Message
                StringData
            }
        }`,
        ConfirmGuestTransferOrder: gql`
        mutation confirmGuestTransferOrder($shoppingBasketItems: [ShoppingBasketItem], $shippingTypeId: ID, $email: String, $customerInvoiceAddress: CustomerAddressInput, $customerAddress: CustomerAddressInput, $code: String, $referrerId: ID) {
            confirmGuestTransferOrder(shoppingBasketItems: $shoppingBasketItems, shippingTypeId: $shippingTypeId, email: $email, customerInvoiceAddress: $customerInvoiceAddress, customerAddress: $customerAddress, code: $code, referrerId: $referrerId) {
                IsSuccess
                Message
                StringData
            }
        }`,
        LoadRemoteOrderAction: gql`
        mutation loadRemoteOrderAction($guid: String) {
            loadRemoteOrderAction(guid: $guid) {
                IsSuccess
                Message
                StringData
                JsonData
            }
        }`,
    }
};
