<template lang="pug">
  .map-component.bg-td-grey-light
    .map-layer(ref="mapLayer" :style="`${!isControllable ? 'pointer-events:none!important;':''}`")
</template>
<script>
import { config, Language, Map, MapStyle, Marker, NavigationControl, Popup } from "@maptiler/sdk";
import "@maptiler/sdk/dist/maptiler-sdk.css";
import { markRaw } from "vue";
import LoadingSpinner from "@/views/components/loading/LoadingSpinner.vue";
import { Hosts } from "@/graphql/Hosts.ts";
import HostPreviewComponent from "@/views/components/hostPreview/HostPreviewComponent.vue";
import { createComponent } from "@/lib/helper/vue";
import MapHostPreview from "@/views/components/hostPreview/MapHostPreview.vue";

export default {
  name: "MapComponent",
  components: { LoadingSpinner, HostPreviewComponent },
  emits: ["load"],
  props: {
    hosts: Array,
    fitsHosts: {
      type: Boolean,
      default: true
    },
    showsHostPopup: {
      type: Boolean,
      default: true
    },
    location: {
      type: Object,
      validator(loc) {
        return (typeof loc.Latitude === "number" && typeof loc.Longitude === "number");
      },
      default: () => {
        return { Latitude: 51.19414980762646, Longitude: 8.312001898296874 };
      }
    },
    userLocation: {
      type: Object,
      validator(loc) {
        return (typeof loc.Latitude === "number" && typeof loc.Longitude === "number");
      }
    },
    zoom: Number
  },
  data() {
    return {
      isLoaded: false,
      // the map instance
      map: undefined,
      // map controls
      controls: undefined,
      isControllable: true,
      // map data
      hostMarkers: [],
      userLocationMarker: undefined,
      selectedHost: undefined,
      currentLocation: [51.19105718109026, 8.310565614792827],
      currentZoom: 5
    };
  },
  watch: {
    userLocation(value) {
      this.showUserLocation(value);
    },
    async zoom(zoom) {
      await this.flyTo({ zoom });
    },
    async location(loc) {
      await this.flyTo({ center: [loc.Longitude, loc.Latitude] });
    },
    isControllable(value) {
      if (!this.isLoaded) return;
      if (!value) {
        this.map.removeControl(this.controls);
      } else {
        this.map.addControl(this.controls);
      }
    },
    async hosts(newHosts) {
      this.clearHostMarkers();
      newHosts.forEach((newHost) => this.addMarker(newHost));
      if (!this.fitsHosts) return;
      await this.zoomMarkersIntoView(this.hosts);
    }
  },
  async mounted() {
    config.apiKey = "KUxaWUW4IlIP2NKn6b9M";
    config.primaryLanguage = Language.GERMAN;
    if (this.location) this.currentLocation = [this.location.Longitude, this.location.Latitude];
    this.currentZoom = this.zoom || 5;
    this.controls = markRaw(new NavigationControl({
      showCompass: false,
      visualizePitch: false
    }));
    const mapArgs = {
      navigationControl: false,
      geolocateControl: false,
      dragRotate: false,
      container: this.$refs.mapLayer,
      style: MapStyle.STREETS,
      zoom: this.currentZoom
    };
    if (this.currentLocation) mapArgs.center = this.currentLocation;
    this.map = markRaw(new Map(mapArgs));
    this.map.addControl(this.controls);
    this.map.once("load", () => {
      this.isLoaded = true;
      this.$emit("load");
    });
    this.map.on("zoom", () => {
      this.currentZoom = this.map.getZoom();
    });
    this.map.on("move", () => {
      const { lng, lat } = this.map.getCenter();
      this.currentLocation = [lat, lng];
    });
    this.map.scrollZoom.disable();
    if (this.hosts?.length) {
      this.hosts.forEach((host) => {
        this.addMarker(host);
      });
    }
    this.showUserLocation(this.userLocation);
    if (this.fitsHosts) await this.zoomMarkersIntoView(this.hosts);
    window.addEventListener("keydown", this.enableZoom);
    window.addEventListener("keyup", this.disableZoom);
  },
  unmounted() {
    window.removeEventListener("keydown", this.enableZoom);
    window.removeEventListener("keyup", this.disableZoom);
  },
  destroyed() {
    window.removeEventListener("keydown", this.enableZoom);
    window.removeEventListener("keyup", this.disableZoom);
  },
  methods: {
    disableZoom(e) {
      if (!e || e.key !== "Control") return;
      this.map.scrollZoom.disable();
    },
    enableZoom(e) {
      if (!e || e.key !== "Control") return;
      this.map.scrollZoom.enable();
    },
    showUserLocation(location) {
      if (this.userLocationMarker) this.userLocationMarker.remove();
      if (!location?.Latitude || !location?.Longitude) return;
      this.userLocationMarker = new Marker({ color: "#488345" });
      this.userLocationMarker.setLngLat([location.Longitude, location.Latitude]).addTo(this.map);
      if (!this.userLocation.Name) return;
      const popup = new Popup({ closeButton: false });
      popup.setHTML(`<div class="p-1 text-center"><b>${this.userLocation.Name}</b></div>`);
      this.userLocationMarker.setPopup(popup);
    },
    async resolveHost(host) {
      return this.$apollo.query({
        query: Hosts.Queries.MapHostDetails,
        variables: {
          id: host.id
        }
      }).then((response) => response?.data?.host || host);
    },
    /**
     * Calculate bounding points for the given locations
     * @param { Array<{Latitude:number;Longitude:number}> } locations
     * @return { [[number,number],[number,number]] | undefined }
     */
    calculateBoundingPoints(locations) {
      if (!locations?.length) return;
      const getMinMax = (values) => {
        return [Math.min(...values), Math.max(...values)];
      };
      const [minLat, maxLat] = getMinMax(locations.map((h) => h.Latitude));
      const [minLng, maxLng] = getMinMax(locations.map((h) => h.Longitude));

      return [
        [minLng, minLat],
        [maxLng, maxLat]
      ];
    },
    getMarkerForHost(host) {
      return this.hostMarkers.find((m) => m.hostId === host.id);
    },
    clearHostMarkers() {
      this.hostMarkers.forEach((marker) => marker.remove());
      this.hostMarkers = [];
    },
    async addMarker(host) {
      if (this.getMarkerForHost(host)) return;
      const marker = new Marker({ color: "#f5a843" });
      marker.hostId = host.id;
      marker.setLngLat([host.Longitude, host.Latitude]);
      this.hostMarkers.push(marker);
      if (!this.showsHostPopup) {
        marker.addTo(this.map);
        return;
      }
      const popup = new Popup({ className: "host-popup", closeButton: false, maxWidth: "340px" });
      popup.on("open", async (e) => {
        const center = e.target?.getLngLat();
        if (!center) return;
        const flyParams = { center, speed: 5 };
        if (this.currentZoom < 10) flyParams.zoom = 10;
        this.$nextTick(async () => {
          popup.setHTML(`<div class="host-popup-placeholder loader"></div>`);
          const resolvedHost = await this.resolveHost(host);
          const previewComponent = createComponent(MapHostPreview, {
            propsData: {
              host: resolvedHost,
              jumpTo: async () => this.flyTo(flyParams)
            },
            router: this.$router
          });
          previewComponent.$mount(document.querySelector(".host-popup-placeholder"));
        });
      });
      marker.setPopup(popup).addTo(this.map);
    },
    async flyTo(options) {
      return new Promise((resolve, reject) => {
        this.isControllable = false;
        this.map.once("error", (e) => {
          this.isControllable = true;
          reject(e);
        });
        this.map.once("moveend", (e) => {
          if (!e?.mapFlight) return;
          this.isControllable = true;
          resolve();
        });
        this.map.flyTo(options, { mapFlight: true });
      });
    },
    async zoomMarkersIntoView(hosts) {
      return new Promise((resolve, reject) => {
        this.isControllable = false;
        this.map.once("error", (e) => {
          this.isControllable = true;
          reject(e);
        });
        this.map.once("moveend", () => {
          this.isControllable = true;
          resolve();
        });
        const locations = hosts.map((host) => {
          return {
            Latitude: host.Latitude,
            Longitude: host.Longitude
          };
        });
        if (this.userLocation) {
          locations.push(this.userLocation);
        }
        const bounds = this.calculateBoundingPoints(locations);
        if (!bounds) return;
        this.map.fitBounds(bounds, { padding: 50, speed: 10 });
      });

    }
  }
};
</script>
<style lang="scss">
.map-component {
  @keyframes hostPopupAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes markerAnimation {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .host-popup-placeholder, .host-popup {
    width: 100%;
  }

  .host-popup-placeholder {
    aspect-ratio: 0.93 / 1;
  }

  .host-popup {
    aspect-ratio: 4 / 3;
    animation: hostPopupAnimation 0.4s normal forwards;

    .maplibregl-popup-content {
      background: transparent;
    }

    .host-preview {
      margin-bottom: 0 !important;
    }
  }

  .maplibregl-marker svg {
    transform-origin: center bottom;
    animation: markerAnimation 0.4s normal forwards;
  }

  .maplibregl-popup-content {
    padding: 0;
  }
}
</style>
<style scoped lang="scss">
.map-component {
  &, .map-layer, .loading-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
