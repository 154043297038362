<template lang="pug">
  ModalComponent(title="Bei touriDat sind Sie sicher!" @close="$emit('close')")
    .p-3
      // Winner
      section.py-3.winner-2018(v-if="hasWinner")
        .text-center
          img.seal(src="https://s3-cloud.td-cdn.de/Images/makaira/marketing/DtGV/2022/Testsieger_Gesamt_quer.png")
          .h2.mt-3 geprüfter Testsieger seit 2018
        p.p-3.
          touriDat ist offizieller Testsieger 2018, 2019, 2020 sowie 2022 und wurde durch die Deutsche Gesellschaft für Verbraucherstudien ausgezeichnet. Somit wurden die erheblichen Preisvorteile mit touriDat von unabhängiger Seite ebenso geprüft und bestätigt wie das einfache Handling und der 100%-Käuferschutz.
        .text-center
          router-link.button.button-primary.button-td-grey(:to="{path:'/winner-2018'}" target="_blank") mehr erfahren
      // touriDays
      section.py-3.touridays(v-if="hasTouriDays")
        .text-center
          img.seal(src="https://s3-cloud.td-cdn.de/Images/Logos/touridays/branding/touridays-branding-100x100.png")
          .h2.mt-3 touriDays - 100% Käuferschutz
        p.p-3.
          touriDat sichert Ihren Kauf gegen finanzielle Verluste ab. Beispielsweise im Falle einer Insolvenz des Gastgebers oder bei Betriebsschließung erhalten Sie Ihr Geld zurück. Darüber hinaus bietet Ihnen touriDat generell ein 30-tägiges Rückgaberecht.
        .text-center
          router-link.button.button-primary.button-td-grey(:to="{path:'/touridays'}" target="_blank") mehr erfahren
      // Trustami
      section.py-3.trustami(v-if="hasTrustami")
        .text-center
          img.seal(src="https://app.trustami.com/seals/56bb3a3ccc96c5432a8b476a.png")
          .h2.mt-3 über 265.000 positive Bewertungen
        p.p-3.
          Seit 25 Jahren bietet touriDat Kurzreisen und Hotelangebote zu besonders attraktiven Konditionen. Mit über 255.000 Bewertungen ist touriDat zudem erfolgreichster und beliebtester Anbieter von touriDays in Deutschland. Darüber hinaus bietet touriDat derzeit als einziger Anbieter einen 100%igen Käuferschutz gegen finanzielle Verluste wie z. B. Insolvenz des Gastgebers, Inhaberwechsel oder Betriebsschließung.
        .text-center
          a.button.button-primary.button-td-grey(href="https://www.trustami.com/erfahrung/touridat-com-bewertung" target="_blank") mehr erfahren
      // SSL
      section.py-3.trustami(v-if="hasSSL")
        .text-center
          img.seal(src="https://s3-cloud.td-cdn.de/Images/Logos/companys/ssl-120x120.png")
          .h2.mt-3 SSL - Datensicherheit
        p.p-3.
          Durch das SSL-Zertifikat ist die Übermittlung Ihrer vertraulichen Daten bei touriDat sicher. Um Ihre bank- und personenbezogenen Daten vor kriminellem Missbrauch zu schützen, werden diese durch modernste Sicherheitstechnologie verschlüsselt.
      // CrefoZert
      section.py-3.trustami(v-if="hasCrefo")
        .text-center
          img.seal(src="https://s3-cloud.td-cdn.de/Images/Logos/companys/crefozert-2024-w120.png")
          .h2.mt-3 CrefoZert 2024
        p.p-3.
          Als seriöser Anbieter ist touriDat von der unabhängigen Creditreform überprüft und für seine hervorragende Bonität ausgezeichnet worden. Ihr Kauf bei touriDat ist absolut sicher und in den besten Händen.
        .d-flex.justify-content-between.px-3
          a.button.button-primary.button-td-grey(href="https://s3-cloud.td-cdn.de/Promo/CrefoZert/2024/crefozert-td-ger.pdf" target="_blank") Zertifikat (Deutsch)
          a.button.button-primary.button-td-grey(href="https://s3-cloud.td-cdn.de/Promo/CrefoZert/2024/crefozert-td-eng.pdf" target="_blank") Zertifikat (English)
  //.modal-dialog.m-0.w-100
    .modal-content
      .modal-header
        h3.modal-title.w-100.ml-3 Bei touriDat sind Sie sicher!
        .float-right
          a.close(@click="$emit('close');")
            font-awesome-icon(icon="times")
      .modal-body.p-0.m-0
        .p-3
          // Winner
          section.py-3.winner-2018(v-if="hasWinner")
            .text-center
              img.seal(src="https://s3-cloud.td-cdn.de/Images/makaira/marketing/DtGV/Testsieger_Gesamt_quer-compressed.png")
              .h2.mt-3 geprüfter Testsieger seit 2018
            p.p-3.
              touriDat ist offizieller Testsieger und wurde im Oktober 2018 durch die Deutsche Gesellschaft für Verbraucherstudien ausgezeichnet. Somit wurden Ihre erheblichen Preisvorteile mit touriDat von unabhängiger Seite ebenso bestätigt wie das einfache Handling und der 100% Käuferschutz.
            .text-center
              router-link.button.button-primary.button-td-grey(:to="{path:'/winner-2018'}" target="_blank") mehr erfahren
          // touriDays
          section.py-3.touridays(v-if="hasTouriDays")
            .text-center
              img.seal(src="https://s3-cloud.td-cdn.de/Images/Logos/touridays/branding/touridays-branding-100x100.png")
              .h2.mt-3 touriDays - 100% Käuferschutz
            p.p-3.
              touriDat sichert Ihren Kauf gegen finanzielle Verluste ab. Beispielsweise im Falle einer Insolvenz des Gastgebers oder bei Betriebsschließung erhalten Sie Ihr Geld zurück. Darüber hinaus bietet Ihnen touriDat generell ein 30-tägiges Rückgaberecht.
            .text-center
              router-link.button.button-primary.button-td-grey(:to="{path:'/touridays'}" target="_blank") mehr erfahren
          // Trustami
          section.py-3.trustami(v-if="hasTrustami")
            .text-center
              img.seal(src="https://app.trustami.com/seals/56bb3a3ccc96c5432a8b476a.png")
              .h2.mt-3 über 265.000 positive Bewertungen
            p.p-3.
              Seit 25 Jahren bietet touriDat Kurzreisen und Hotelangebote zu besonders attraktiven Konditionen. Mit über 255.000 Bewertungen ist touriDat zudem erfolgreichster und beliebtester Anbieter von touriDays in Deutschland. Darüber hinaus bietet touriDat derzeit als einziger Anbieter einen 100%igen Käuferschutz gegen finanzielle Verluste wie z. B. Insolvenz des Gastgebers, Inhaberwechsel oder Betriebsschließung.
            .text-center
              a.button.button-primary.button-td-grey(href="https://www.trustami.com/erfahrung/touridat-com-bewertung" target="_blank") mehr erfahren
          // SSL
          section.py-3.trustami(v-if="hasSSL")
            .text-center
              img.seal(src="https://s3-cloud.td-cdn.de/Images/Logos/companys/ssl-120x120.png")
              .h2.mt-3 SSL - Datensicherheit
            p.p-3.
              Durch das SSL-Zertifikat ist die Übermittlung Ihrer vertraulichen Daten bei touriDat sicher. Um Ihre bank- und personenbezogenen Daten vor kriminellem Missbrauch zu schützen, werden diese durch modernste Sicherheitstechnologie verschlüsselt.
          // CrefoZert
          section.py-3.trustami(v-if="hasCrefo")
            .text-center
              img.seal(src="https://s3-cloud.td-cdn.de/Images/Logos/companys/crefozert-w120.png")
              .h2.mt-3 CrefoZert | 2021/22
            p.p-3.
              Als seriöser Anbieter ist touriDat von der unabhängigen Creditreform überprüft und für seine hervorragende Bonität ausgezeichnet worden. Ihr Kauf bei touriDat ist absolut sicher und in den besten Händen.
            .d-flex.justify-content-between.px-3
              a.button.button-primary.button-td-grey(href="https://s3-cloud.td-cdn.de/Promo/CrefoZert/crefozert-td-ger.pdf" target="_blank") Zertifikat (Deutsch)
              a.button.button-primary.button-td-grey(href="https://s3-cloud.td-cdn.de/Promo/CrefoZert/crefozert-td-eng.pdf" target="_blank") Zertifikat (English)
      .modal-footer
        .row.my-3
          .col-12
            .text-right
              button.button.button-secondary.button-td-grey(@click="$emit('close')") schließen
</template>

<script>
import ModalComponent from "@/views/components/modals/ModalComponent";
export default {
  name: "SealsModal",
  components: { ModalComponent },
  props: {
    hasWinner: Boolean,
    hasTouriDays: Boolean,
    hasTrustami: Boolean,
    hasSSL: Boolean,
    hasCrefo: Boolean,
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
:global(.seals-modal) {
  max-height: calc(100vh - 50px) !important;
}

.modal-body {
  section {
    &:not(:last-child) {
      border-bottom: 1px solid $td-grey-light !important;
    }
    p {
      text-align: justify;
    }
  }
}
</style>
