import gql from "graphql-tag";

export const Hosts = {
  Queries: {
    TippsHosts: gql`
        query TippsHosts {
            tippsHosts {
                id
                SystemHostId
                Name
                Url
                Stars
                Images
                CityRegion
                Items {
                    id
                    Nights
                    Persons
                    Catering
                    CurrentPrice
                }
            }
        }
    `,
    MapHostDetails: gql`
        query Host($id: ID) {
            host(id: $id) {
                id
                Name
                Url
                Stars
                CityRegion
                Images
                Items {
                    id
                    RegularPrice
                    DiscountedPrice
                    CurrentPrice
                    Persons
                    Nights
                    Catering
                }
            }
        }
    `,
    HostByName: gql`
        query hostByName($name: String, $lang: String) {
            hostByName(name: $name, lang: $lang) {
                id
                Name
                Latitude
                Longitude
                Url
                Stars
                Images
                CityRegion
                HostCriteria {
                    Category
                    Icon
                    Values
                }
                CustomAttributes {
                    Title
                    HotelDescription
                    VimeoVideoUrl
                    HotelAddress
                    Subtitle
                    EnvironmentDescription
                    SeoDescription
                    SeoGeneralDescription
                    SeoEquipmentDescription
                    SeoEnvironmentDescription
                }
                HostViewTemplateId
                Items {
                    id
                    Name
                    SystemItemId
                    WebTemplateId
                    Nights
                    Persons
                    Catering
                    RegularPrice
                    DiscountedPrice
                    LiveShoppingPrice
                    CurrentPrice
                    AdvertisedFrom
                    AdvertisedTill
                    IsInLiveShopping
                    IsTouriTippsItem
                    StockAvailableQuantityRegularPrice
                    StockReservedQuantityRegularPrice
                    StockAvailableQuantityDealPrice
                    StockReservedQuantityDealPrice
                    CustomAttributes {
                        Accomodations
                        Custom_Product_HotelServices
                        Custom_Hotel_Description
                        Custom_Host_Address
                        Custom_Product_VimeoURL
                        Custom_Product_Arrangement
                        Custom_Product_TouristServices
                        Custom_Product_ServicesExtra
                        Custom_Product_Bookability
                        Custom_Product_Specials
                        ProductValidity
                    }
                }
                SelectionVoucher {
                    Headline
                    Subline
                    VoucherImage
                    ChecklistElements
                    Description
                    IsStaticSelectionVoucher
                    JsonItems
                    Items {
                        id
                        Name
                        SystemItemId
                        Nights
                        Persons
                        Catering
                        RegularPrice
                        DiscountedPrice
                        LiveShoppingPrice
                        CurrentPrice
                        AdvertisedFrom
                        AdvertisedTill
                        IsInLiveShopping
                        IsTouriTippsItem
                        StockAvailableQuantityRegularPrice
                        StockReservedQuantityRegularPrice
                        StockAvailableQuantityDealPrice
                        StockReservedQuantityDealPrice
                        CustomAttributes {
                            Custom_Product_HotelServices
                            Custom_Product_Bookability
                            Custom_Hotel_Name
                            Custom_Product_Images
                        }
                        Host {
                            id
                            Name
                            Latitude
                            Longitude
                            Stars
                            Images
                            CityRegion
                        }
                    }
                }
            }
        }
    `,
    MapHost: gql`
        query host($id: ID) {
            host(id: $id) {
                id
                Name
                Latitude
                Longitude
                Url
                Stars
                Images
                CityRegion
                HostViewTemplateId
                CustomAttributes {
                    Title
                    Subtitle
                    HotelDescription
                    HotelAddress
                    EnvironmentDescription
                    SeoDescription
                    SeoGeneralDescription
                    SeoEquipmentDescription
                    SeoEnvironmentDescription
                }
                Items {
                    id
                    RegularPrice
                    CurrentPrice
                    Persons
                    Nights
                    Catering
                }
            }
        }
    `,
    Host: gql`
        query host($id: ID) {
            host(id: $id) {
                id
                Name
                Latitude
                Longitude
                Url
                Stars
                Images
                CityRegion
                HostViewTemplateId
                CustomAttributes {
                    Title
                    Subtitle
                    HotelDescription
                    HotelAddress
                    EnvironmentDescription
                    SeoDescription
                    SeoGeneralDescription
                    SeoEquipmentDescription
                    SeoEnvironmentDescription
                }
            }
        }
    `,
    HostsBySelectionVoucherId: gql`
        query hostsBySelectionVoucherId($selectionVoucherId: ID) {
            hostsBySelectionVoucherId(selectionVoucherId: $selectionVoucherId) {
                id
                Name
                Latitude
                Longitude
                Url
                Stars
                Images
                CityRegion
                CustomAttributes {
                    Title
                    Subtitle
                    HotelDescription
                    HotelAddress
                    EnvironmentDescription
                    SeoDescription
                    SeoGeneralDescription
                    SeoEquipmentDescription
                    SeoEnvironmentDescription
                }
            }
        }
    `,
    HostUrl: gql`
        query hostUrl($hostId: ID, $lang: String) {
            hostUrl(hostId: $hostId, lang: $lang)
        }
    `
  }
};
