<template lang="pug">
  .map-host-preview.position-relative
    HostPreviewComponent(:host="host" link-to-new-tab)
    button.jump-to-btn.button.button-primary.button-themed(title="Gastgeber auf der Karte anzeigen" v-if="jumpTo" @click="jumpTo")
      font-awesome-icon(icon="search-location")
</template>
<script>
import HostPreviewComponent from "@/views/components/hostPreview/HostPreviewComponent.vue";

export default {
  name: "MapHostPreview",
  components: { HostPreviewComponent },
  props: {
    host: Object,
    jumpTo: {
      type: Function,
      required: true
    }
  }
};
</script>
<style scoped lang="scss">
.jump-to-btn {
  padding: 0.5rem 0.75rem;
  position: absolute;
  right: 15px;
  bottom: 0;
  transform: translateY(100%);
}
</style>
