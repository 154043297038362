import { render, staticRenderFns } from "./ShoppingBasketButton.vue?vue&type=template&id=700c1e7c&scoped=true&lang=pug&"
import script from "./ShoppingBasketButton.vue?vue&type=script&lang=ts&"
export * from "./ShoppingBasketButton.vue?vue&type=script&lang=ts&"
import style0 from "./ShoppingBasketButton.vue?vue&type=style&index=0&id=700c1e7c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700c1e7c",
  null
  
)

export default component.exports