import gql from "graphql-tag";

export const MarketingPromoContestParticipants = {
    Queries: {
        MarketingPromoContestPrizeTicket: gql`
        query marketingPromoContestPrizeTicket($marketingPromoContestPrizeId: ID) {
            marketingPromoContestPrizeTicket(marketingPromoContestPrizeId: $marketingPromoContestPrizeId) {
                id
                createdAt
                updatedAt
                deletedAt
                MarketingPromoContestId
                MarketingPromoParticipantId
                MarketingPromoParticipantReferrerId
                Ticket
                SignUpThroughSource
                RawRequest
            }
        }`,
    },
    Mutations: {
        CreateMarketingPromoContestParticipant: gql`
        mutation createMarketingPromoContestParticipant($marketingPromoContestId: ID, $email: String, $token: String, $marketingPromoParticipantReferrerGuid: String, $signUpThroughSource: String) {
            createMarketingPromoContestParticipant(marketingPromoContestId: $marketingPromoContestId, email: $email, token: $token, marketingPromoParticipantReferrerGuid: $marketingPromoParticipantReferrerGuid, signUpThroughSource: $signUpThroughSource) {
                IsSuccess
                Message
            }
        }`,
    },
};
