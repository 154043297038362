<template lang="pug">
  footer#footer
    .container-hd.bg-td-grey-basic.pb-1
      .container
        .hotline.text-center.pt-3
          .h2 Bestell-Hotline
          .h1.phone(style="font-size:24px") {{hotline.phone}}
          .hours {{hotline.hours}} (kostenfrei aus dem dt. Festnetz - Mobilfunk abweichend)
        hr
        nav.brands
          .row
            .col-6.col-md-3.text-center(v-for="brand in footerBrands")
              .mb-5.mb-md-0
                router-link.txt-white(v-if="brand.route" :to="brand.route")
                  img.brand-logo(:src="brand.imageUrl")
                  br
                  .brand-description(v-html="brand.description")
                a.txt-white(v-if="brand.link" :href="brand.link")
                  img.brand-logo(:src="brand.imageUrl")
                  br
                  .brand-description(v-html="brand.description")
        hr
        .row.my-5
          .trust-security.text-center.col-12.col-md-6
            .h2 Sicherheit & Vertrauen
            a(@click="showSealsModal(['winner','ssl','trustami','crefo'])")
              .seals.text-center
                img.seal.lozad.cursor-pointer(src="https://s3-cloud.td-cdn.de/Images/Logos/companys/ssl-120x120.png" alt="SSL - Sichere Verbindung")
                img.seal.lozad.cursor-pointer(src="https://s3-cloud.td-cdn.de/Images/Logos/companys/crefozert-2024-w120.png" alt="CrefoZert - Bonitätszertifikat")
                // img.lozad(src="https://app.trustami.com/seals/56bb3a3ccc96c5432a8b476a.png" alt="trustami - über " + t.main.trustamiVotes + " positive Bewertungen")
                img.seal.lozad.cursor-pointer(src="https://app.trustami.com/seals/56bb3a3ccc96c5432a8b476a.png" alt="trustami - über " + '255.000' + " positive Bewertungen")
            //- <!--Trustami Vertrauenssiegel als Text-->
            .widget_container_text_only.mb-4.mt-1
            // TrustBox widget - Mini
            .trustpilot-widget(data-locale="de-DE", data-template-id="53aa8807dec7e10d38f59f32", data-businessunit-id="5fb25ba2e28cd80001f22278", data-style-height="150px", data-style-width="100%", data-theme="light")
              a(href="https://de.trustpilot.com/review/touridat.com", target="_blank", rel="noopener") Trustpilot
            // End TrustBox widget
          .awards.text-center.col-12.col-md-6
            .h2 Awards & Auszeichnungen
            a(href="/winner-2018?ref=from_footer")
              img.lozad(src="https://s3-cloud.td-cdn.de/Images/makaira/marketing/DtGV/2022/Testsieger_Gesamt_hoch.png" alt="touriDat | Testsieger Gesamt 2022")
              img.lozad(src="https://s3-cloud.td-cdn.de/Images/makaira/marketing/DtGV/2022/1Platz_Preisvorteil_hoch.png" alt="touriDat | 1. Platz Preisvorteil 2022")
        hr
        .payment-methods.text-center.mb-5
          .h2 Zahlungsarten
          a.text-center.cursor-pointer.mt-3(@click="showPaymentTypesModal")
            img.lozad.img-fluid.my-3(src="https://s3-cloud.td-cdn.de/Images/makaira/foreign-logos/transfer.png" style="max-height: 60px;" alt="Vorkasse Logo")
            img.lozad.img-fluid.my-3(src="https://s3-cloud.td-cdn.de/Images/makaira/foreign-logos/paypal/de-pp-logo-150px.png" alt="Paypal Logo")
            img.lozad.img-fluid.ml-3.my-3(src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.png" alt="Klarna Logo")
            img.lozad.img-fluid.ml-3.my-3(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/makaira/foreign-logos/amazon_pay.svg" alt="amazonPay Logo")
            img.lozad.img-fluid.visa.ml-3.my-3(src="https://s3-cloud.td-cdn.de/Images/makaira/foreign-logos/visa/Logo_Visa.svg.png" alt="VISA Logo")
            img.lozad.img-fluid.ml-3.my-3(src="https://s3-cloud.td-cdn.de/Images/makaira/foreign-logos/mastercard/mc_symbol_opt_73_3x.png" alt="MasterCard Logo")

        .info.text-center.mb-5.p-2
          img.lozad.img-fluid.mb-3(src="https://s3-cloud.td-cdn.de/Images/Logos/Logo2016_tD_2016_Claim_negativ_width_300.png" alt="touriDat-Logo")
          p
            | (1) = Vom Beherbergungsbetrieb verlangter regulärer Preis ohne Rabatt für die im Gutschein enthaltenen Leistungen.
            br
            | (2) = Rabatt bezogen auf den vom Beherbergungsbetrieb verlangten regulären Preis ohne Rabatt für die im Gutschein enthaltenen Leistungen.
            br
            | Alle Preise inklusive touriDays-Gebühr, gesetzlicher Mehrwertsteuer und zuzüglich Versandkosten. *Pflichtfeld
          p © {{moment(new Date()).format('YYYY')}} touriDat GmbH & Co. KG - Alle Rechte vorbehalten.
          p
            router-link.txt-white(:to="{ path: '/impressum'}") Impressum
</template>

<script>
import { brands } from "@/lib/brands";
import * as t from "@/store/main.json";
import { showSealsModal } from "@/lib/components/sealsModal";
import PaymentTypesModal from "@/views/components/modals/paymentTypesModal/PaymentTypesModal";
import moment from "moment";

export default {
  name: "AppFooter",
  setup() {
    const footerBrands = brands.filter((brand) =>
      ["touriDays", "touriGolf", "touriTipps", "touriBook"].includes(brand.name)
    );
    return {
      footerBrands,
      hotline: {
        phone: t.main.hotlinePhone,
        hours: t.main.buisnessHourHotline,
      },
      moment,
    };
  },
  methods: {
    showSealsModal,
    showPaymentTypesModal() {
      this.$modal.show(
        PaymentTypesModal,
        {},
        {
          width: "500px",
          height: "auto",
          classes: ["modalMaxHeight", "modalFitContents", "rounded-0"],
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";
@import "@/assets/styles/mediaqueries.scss";

#footer {
  color: $white;
  font-size: 12px;
  margin-bottom: 0;

  @include sm {
    margin-bottom: 15px;
  }

  @include md {
    margin-bottom: 25px;
  }

  .container-hd {
    min-height: 30vh;
    .branding {
      aspect-ratio: 1;
      width: 50px;
    }
    .branding-subline {
      font-size: 20px;
      font-weight: 500;
    }

    .brands {
      a {
        .brand-logo {
          height: 100%;
          width: auto;
          max-height: 37px;
        }
        .brand-description {
          display: inline-block;
        }

        &:hover {
          .brand-description {
            text-decoration: underline;
          }
        }
      }
    }

    .trust-security {
      .seals {
        .seal {
          height: auto;
          width: 100%;
          max-width: 60px;
          vertical-align: top;
          margin: 0 5px;
        }
      }
    }

    .awards {
      img {
        height: auto;
        width: 100%;
        max-width: 75px;
        vertical-align: bottom;
        margin: 0 5px;
      }
    }

    .payment-methods {
      img {
        height: 100%;
        width: auto;
        max-height: 32px;
        margin: 0 5px;
      }
    }
  }
}
</style>
