import { render, staticRenderFns } from "./ShoppingBasketOverview.vue?vue&type=template&id=004709ac&scoped=true&lang=pug&"
import script from "./ShoppingBasketOverview.vue?vue&type=script&lang=js&"
export * from "./ShoppingBasketOverview.vue?vue&type=script&lang=js&"
import style0 from "./ShoppingBasketOverview.vue?vue&type=style&index=0&id=004709ac&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "004709ac",
  null
  
)

export default component.exports