<template lang="pug">
  .category-navigation.desktop.position-relative
    ul.d-flex.justify-content-between
      li.static.d-inline-block(:class="{'active':$route.name === 'Home'}")
        router-link.label(:to="{path:'/',query:{ref:'nav_tree'}}") Startseite
      li.sub-category.d-inline-block(v-for="category in categories" :class="{'active':getIsActiveItem(category)}")
        router-link.label(:to="{path:`/${baseRoutePath}/${category.Url}`,query:queryWith({ref:'nav_tree'})}") {{category.Name}}
        TreeMenu.p-3(v-if="category.Children" :nodes="category.Children" :level="0" ref-type="nav_tree" :base-path="`/${baseRoutePath}/`")

</template>

<script>
import TreeMenu from "@/views/components/navbar/partials/TreeMenu";
import { Categories } from "@/graphql/Categories.ts";
import { objectWithout } from "@/lib/helper/object";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Navbar",
  components: { TreeMenu },
  props: {
    baseRouteName: String
  },
  data() {
    return {
      categoryNavbar: "",
      categories: []
    };
  },
  mounted() {
    try {
      this.loadNavbarCategories();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  computed: {
    baseRoutePath() {
      if (this.baseRouteName === "Map") return "karte";
      return "kategorien";
    }
  },
  methods: {
    queryWith(query) {
      // remove the "query" property from search when routing to a category
      const currentQuery = objectWithout(this.$route?.query, ["query"]) || {};
      if (this.$route.name === "Map") {
        currentQuery.viewMode = "map";
      }
      return {
        ...currentQuery,
        ...query
      };
    },
    loadNavbarCategories() {
      try {
        this.$apollo
          .query({
            query: Categories.Queries.NavbarCategories
          })
          .then(({ data }) => {
            if (!data || !data.navbarCategories) {
              return;
            }
            this.categories = data.navbarCategories;
          });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    getIsActiveItem(navItem) {
      return this.$route.path.indexOf(`/${this.baseRoutePath}/${navItem.Url}`) !== -1;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/_colors.scss";

.category-navigation {
  --nav-highlight-color: #{$tdays-light};
  z-index: 10;

  ul {
    list-style: none !important;
  }

  &.mobile {
    a {
      cursor: pointer;
    }

    a.label {
      padding: 12px 0;
      display: block;

      &:not(.category) {
        font-weight: 500;
      }

      &.category {
        color: $td-grey-basic;
      }

      &.branches {
        position: relative;

        i {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0%, -50%);
        }
      }
    }

    > ul {
      padding-left: 0;

      ul,
      .label.category {
        padding-left: 15px;
      }
    }

    li {
      .branch {
        display: none;
      }

      .label.branches {
        i,
        i * {
          pointer-events: none !important;
        }
      }

      &.open {
        & > .branch {
          display: block;
        }

        & > .label.branches {
          i.plus {
            display: none;
          }

          i.minus {
            display: block;
          }

          &:after {
            content: "";
            height: 3px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $td-grey-basic;
            transform-origin: left center;
            animation: openBranchLineAnimation 0.25s normal forwards;

            @keyframes openBranchLineAnimation {
              0% {
                transform: scaleX(0);
              }
              100% {
                transform: scaleX(1);
              }
            }
          }
        }
      }
    }
  }

  &.desktop {
    & > ul > li > a,
    [data-level="0"] > a {
      font-weight: 500;
      transition: all 0.5s;
    }

    & > ul {
      &,
      & > li.sub-category ul {
        list-style: none !important;
      }

      &,
      & > li.sub-category > ul {
        padding: 0;
        margin: 0;

        & > li > ul {
          margin: 0;
          padding-left: 12px;
        }
      }

      li[data-level="0"] {
        margin-bottom: 0.5rem;
      }

      & > li {
        & > a {
          text-decoration: none;
          display: block;
          position: relative;
          overflow: hidden;
        }

        &.sub-category,
        &.static {
          & > a {
            padding-bottom: 3px;

            &:before {
              content: "";
              height: 2px;
              background-color: var(--nav-highlight-color);
              width: 100%;
              position: absolute;
              left: 0;
              bottom: 0;
              opacity: 0;
              transform: scale(0, 1);
              transform-origin: left center;
              transition: transform 0.25s, opacity 0.25s;
              z-index: -1;
              pointer-events: none;
            }
          }
        }

        &:hover,
        &.active {
          & > a {
            color: var(--nav-highlight-color);

            &:before {
              opacity: 1;
              transform: scale(1, 1);
            }
          }
        }
      }

      > .sub-category {
        & > ul {
          animation: mainNavFadeIn 0.25s normal forwards;
          column-count: 4;
          position: absolute;
          left: 0;
          bottom: 0;
          transform: translate(0%, 100%);
          display: none;
          background-color: $white;
          border: 1px solid var(--nav-highlight-color);
          width: 100%;

          @keyframes mainNavFadeIn {
            0% {
              bottom: 15px;
              opacity: 0;
            }
            100% {
              bottom: 0;
              opacity: 1;
            }
          }
        }

        &:hover > ul {
          display: inline-block;
        }
      }
    }
  }

  &.mobile,
  &.desktop {
    & > ul {
      & > .sub-category {
        a {
          &:hover,
          &.router-link-exact-active {
            color: var(--nav-highlight-color);
          }
        }
      }
    }
  }
}
</style>
