import {ShippingTypeEnum} from "@/utils/ShippingTypeEnum.ts";

export default [
    {
        value:ShippingTypeEnum.Email,
        label:'Kostenloser Versand per E-Mail',
        description:'Wählen Sie diese Versandart, bekommen Sie nach Abschluss des Kaufprozesses eine E-Mail mit dem Gutschein kostenlos zugesendet.',
        icon:'at',
        price:0
    },
    {
        value:ShippingTypeEnum.Letter,
        label:'touriDat Design-Umschlag',
        description:'Wählen Sie diese Option, erhalten Sie Ihren Gutschein per Post im hochwertigen und optisch ansprechenden Design-Umschlag für nur 3,99 EUR zzgl. 1,99 EUR Versand.',
        icon:'envelope',
        price:5.98
    },
    {
        value:ShippingTypeEnum.Box,
        label:'touriDat Geschenkbox premium',
        description:'Bei dieser Versandart erhalten Sie Ihren Gutschein in einer hochwertigen Geschenkbox mit Schaumstoffeinlage und Magnetverschluss für nur 7,99 EUR zzgl. 3,99 EUR Versand.',
        icon:'box',
        price:11.98
    },
]